.App-header {
  position: relative;
  padding: 20px;
  background-color: #222222;
  display: flex;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid black;
  filter: invert(1);
  background-color: white;
  padding: 5px;
  margin-right: 15px;
  transform: scale(1.1);
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -6px; /* Adjust this value to move the title up */
}

.header-title {
  color: white;
  font-size: 2rem;
  margin: 0;
  padding-bottom: 0px; /* Ensure there’s space between title and subtitle */
}

.header-subtitle {
  color: #ccc;
  font-size: 1rem;
  margin: 0;
  padding-top: 0; /* No extra space between subtitle and title */
}

.author {
  position: absolute;
  bottom: -2px;
  right: 10px;
  color: #ccc;
  font-size: 0.8rem;
}


/* Top bar styling */
.top-bar {
  display: flex;
  justify-content: space-around;
  background-color: #444;
  padding: 10px 0;
  width: 100%;
  cursor: pointer;
}

.bar-option {
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.bar-option .arrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.bar-option:hover {
  background: linear-gradient(135deg, #7a7a7a, #969696); /* Gradient direction and colors */
  -webkit-background-clip: text; /* Ensures gradient is applied to the text */
  -webkit-text-fill-color: transparent;
}

.bar-option:active {
  text-decoration: underline; /* Optionally, underline on hover */
  background: linear-gradient(135deg, #93c4fb, #ce9dff); /* Gradient direction and colors */
  -webkit-background-clip: text; /* Ensures gradient is applied to the text */
  -webkit-text-fill-color: transparent;
}

.bar-option.selected {
  text-decoration: underline;
  font-weight: bold;
  background: linear-gradient(135deg, #bae9ff, #fff); /* Gradient direction and colors */
  -webkit-background-clip: text; /* Ensures gradient is applied to the text */
  -webkit-text-fill-color: transparent;
}

.bar-option .arrow.active {
  transform: rotate(180deg); /* Rotate arrow when active */
}

/* Dropdown bar styling */
.dropdown-bar {
  max-height: 0;
  overflow: hidden;
  background-color: #707070;
  color: white;
  padding: 0 5px;
  text-align: center;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  visibility: hidden;
  will-change: max-height, opacity; /* Hint to the browser about what will change */
  justify-content: space-between; /* Spread items evenly */
}
.dropdown-links {
  justify-content: space-between;
}

.dropdown-link {
  padding: 10px 40px; /* Adjust padding as needed */
  white-space: nowrap;
  margin: 0px 100px;
}

.dropdown-bar.open {
  max-height: 100px; /* Adjust based on your content */
  opacity: 1;
  visibility: visible; /* Make it visible */
  padding: 10px; /* Ensure padding is applied */
}

.dropdown-bar a {
  display: inline-block; /* Ensure each link is on a new line */
  color: white;
  font-weight: bold;
  text-decoration: none; /* Remove underline */
  padding: 0px 20px; /* Adjust padding as needed */
  margin: 0px 20px; /* Add spacing between links */
  transition: background-color 0.5s ease, color 0.5s ease;
}

.dropdown-bar a:hover {
  background: linear-gradient(135deg, #444, #000); /* Gradient direction and colors */
  -webkit-background-clip: text; /* Ensures gradient is applied to the text */
  -webkit-text-fill-color: transparent;
}

.dropdown-bar a:active {
  text-decoration: underline; /* Optionally, underline on hover */
  background: linear-gradient(135deg, #270076, #005a9f); /* Gradient direction and colors */
  -webkit-background-clip: text; /* Ensures gradient is applied to the text */
  -webkit-text-fill-color: transparent;
}
.app-footer {
  text-align: center;
  margin-top: 0px;
  padding: 0px;
  background-color: #222;
  position: absolute;
  width: 100%;
}

.app-footer a {
  color: #f1f1f1;
  text-decoration: none;
}

.app-footer a:hover {
  text-decoration: underline;
}

.header-link {
  text-decoration: none;  /* Removes underline */
  color: inherit;         /* Ensures the text color stays the same */
  display: block;         /* Makes the entire header area clickable */
}

.mean-std-dev-container,
.t-test-container,
.distribution-table-container{
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  background-color: #f8f9fa;
  width:90%;
  display: block;
  margin: 10px auto;
  margin-bottom: 20px;
  text-align: center; /* Center items horizontally */
}
.line-table-container,
.plot-options-container,
.outliers-container,
.calculator-container
{
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
  width:80%;
  display: block;
  align-items: center;
  justify-content: center;
  padding: auto;
  text-align: center; /* Center items horizontally */
}

.stats-container {
  display: flex; /* Ensures elements are on the same line */
  align-items: center; /* Aligns items vertically in the center */
  justify-content: center;
}

.outliers-title {
  color: #333;
  text-align: center;
}

.input-section {
  margin-bottom: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.data-input,
.input-data {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.input-controls {
  display: flex;
  gap: 10px;
  width: 90%;
  margin: 10px auto 20px auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.input-select, .input-number {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 90%;
  margin-top:10px;
}

.process-button {
  display: block;
  width: 100%;
  padding: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.process-button:hover {
  background-color: #0056b3;
}

.results-section {
  background-color: #f9f9f9;
  padding: 10px 20px 20px 20px; /* top, right, bottom, left */
  border-radius: 4px;
}

.results-title {
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  padding-top: 10px;
}

.stats-comparison {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  margin-bottom: 20px;
  gap: 10px; /* Optional: Adjust the space between the items */
}

.stats-box {
  width: 30%; /* Set to desired percentage */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #ccc; /* Optional: Add a border for visibility */
  margin: 0 30px; /* Adjust this value to your liking */
}

.stats-box.before {
  background-color: #e6f3ff;
}

.stats-box.after {
  background-color: #e6ffe6;
}

.arrow {
  font-size: 48px;
  color: #000000;
}

.stats-title {
  margin-top: 0;
  color: #333;
}


.run-results {
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.run-title {
  color: #464646;
  font-size: large;
  text-align: center; /* Center text horizontally */
  margin:10px;
  text-decoration: underline;
}



.stats-item {
  margin-right: 100px; /* Adjust this value to control the space between items */
}

/* Optional: Remove margin from the last item */
.stats-item:last-child {
  margin-right: 0; /* Ensures no extra space after the last item */
}
.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.results-table th, .results-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.results-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.outlier-row {
  background-color: #ffeeee;
}

/* CalcMeanStd.css */



.mean-std-dev-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: white;
}

.mean-std-dev-table th,
.mean-std-dev-table td {
  padding: 2px;
  border: 1px solid #ddd;
  text-align: center;    /* Centers text horizontally */
  vertical-align: middle; /* Centers text vertically */
}


.mean-std-dev-table td:first-child {
  padding: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.mean-std-dev-table th {
  background-color: #f2f2f2;
}

.add-row-button-container {
  text-align: center;
  margin-top: 20px;
}

.add-row-button, .add-line-button {
  width: 50%;
  padding: 5px;
  background-color: #0057b4;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;;
}

.add-row-button:hover, .add-line-button:hover {
  background-color: #0056b3;
}
.remove-row-button:hover {
  background-color: #ff3333;
}

.remove-row-button, .remove-line-button {
  padding: 5px 10px;
  font-size: 15px; /* Adjust the size as needed */
  border: none;
  cursor: pointer;
  align-items: center; /* Center content vertically */
  font-weight: bold;
  transition: background-color 0.2s;
  border-radius: 4px;
}


hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}

/* Title */
.distribution-title {
  color: #333;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

/* Table styling */
.distribution-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.distribution-table th, .distribution-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.distribution-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: center;
}
.distribution-table td:first-child {
  background-color: #f8f9fa00;
  text-align: center;
}
.distribution-table th:first-child,
.distribution-table td:first-child {
  background-color: #f9f9f9;
  border: none;
  text-align: center;
  width: 30px;
}

.distribution-table td {
  background-color: #ffffff;
  text-align: center;
}

.table-header-row th {
  padding: 10px;
  font-size: 16px;
}

/* Row removal button */


/* Form inputs */
.color-picker {
  width: 100%;
  padding: 3px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.input-label, .input-mean, .input-stdev, .line-value-input {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding doesn't affect the width */
  flex-direction: column;
  font-size: 14px;
  align-items: center;
  width: 80%;
  }

/* Plot image placeholder */
.plot-image-container {
  margin: auto;
  border-radius: 20px;
  padding: 10px;
  font-size: 14px;
  background-color: #ffffff;
  width:800px;
  display: block;
}

.plot-image-container p {
  color: #666;
  font-size: 18px;
}

/* Optional: Highlighting row */
.highlighted-row {
  background-color: #ffeeee;
}
.plot-button {
  padding: 15px;
  font-size: 18px;
  background-color: #5b3700; /* Orange color */
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.plot-button:hover {
  background-color: #7e4c00;
}

.remove-line-button {
  background-color: #f44336; /* Red color */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.remove-line-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

.range-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.range-label {
  font-size: 16px;
  color: #555;
}

.range-inputs {
  display: flex;
  justify-content: space-between; /* Space between the X and Y ranges */
  margin-bottom: 20px;
  font-family: Arial, sans-serif; /* Ensures consistent font */
  flex-wrap: nowrap; /* Prevent wrapping to a new line */
}

.x-range, .y-range {
  display: flex; /* Use flexbox for inline label and inputs */
  align-items: center; /* Vertically center elements */
  margin-right: 20px; /* Space between the two range groups */
}

.x-range label, .y-range label {
  font-size: 16px;
  color: #555;
  margin-right: 10px; /* Space between label and input */
}

.input-x-range, .input-y-range {
  width: 100px; /* Fixed width for inputs */
  padding: 10px;
  border: 1px solid #007bff;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
  margin-right: 10px; /* Space between input fields */
}

.input-x-range:focus, .input-y-range:focus {
  border-color: #0056b3; /* Darker blue on focus */
  outline: none; /* Remove default outline */
}

/* Optional: Adjust last input margin */
.input-x-range:last-child, .input-y-range:last-child {
  margin-right: 0; /* Remove right margin for the last input */
}
/* Toggle Line Table Button */
.toggle-line-table-button {
  padding: 10px 15px;
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px; /* Space below */
}

.toggle-line-table-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Line Table Container */

.line-table {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px; 
  border-collapse: collapse;
  border: 1px solid #ddd;
  font-size: 14px;
}

.line-table th, .line-table td {
  font-size: 12px; /* Smaller text */
  padding: 4px;    /* Smaller padding */
}

.line-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.line-table td {
  background-color: #ffffff;
}

/* Additional styles for buttons within the table */
.remove-line-button {
  background-color: #f44336; /* Red color */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.remove-line-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

.range-inputs {
  margin-right: 8px;
}

.axis-label {
  font-size: 12px; /* Smaller text size */
  vertical-align: top; /* Align it closer to the bottom */
  margin-bottom: 30px;
  font-style: italic;
  justify-content: left;
}

.range-input {
  display: flex;
  justify-content: space-between; /* Space between the X and Y ranges */
  margin-top: 10px;
  font-family: Arial, sans-serif; /* Ensures consistent font */
  flex-wrap: nowrap; /* Prevent wrapping to a new line */
}

.error {
  color: red;
  font-size: 12px;
}

.plot-options h2 {
  color: #343a40;
  margin-bottom: 20px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.plot-options h3 {
  color: #495057;
  margin-bottom: 10px;
  font-size: 1.1em;
}


.input-chart-title,
.range-input {
  width: 60%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
}

.input-chart-title:focus,
.range-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

/* Base styles for the toggle buttons */
.toggle-button {
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.line-table-header,
.plot-options-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.line-table-header h2,
.plot-options-header h2 {
  margin-right: 15px;
}

.input-group {
  margin-bottom: 15px;
}
.label-thres-percent {
  display: block;
  margin-bottom: 5px;
}

.calc-prob-button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.calc-prob-button:hover {
  background-color: #45a049;
}
.result {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  text-align: center;
}
.calc-samples{
  width: 35%;
  padding: 10px;
  margin-top:20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.input-samples{
  padding-top:20px;
}
.explanation{
  margin-top: 50px;
}

.tabs {
  margin-bottom: 10px;
  border-bottom: 2px solid #003711; /* Add a bottom border to the tabs */
  color: black;
}

.tabs button {
  margin-right: 10px;
  padding: 10px 20px; /* Add padding for a more button-like appearance */
  border: none;
  background-color: #f3f3f3; /* Default background color */
  cursor: pointer;
  border-radius: 5px 5px 0 0; /* Rounded corners on the top */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.tabs button.active {
  font-weight: bold;
  cursor: default;
  pointer-events: none;
  background-color: #003711; /* Slightly darker background on hover */
  border-bottom: 2px solid #003711;
  color: white;
}

.tabs button:hover {
  background-color: #dadada; /* Change the active tab background */
  color: black;
}

.t-test-table {
  border-collapse: collapse;
  width: 100%; /* Make the table take full width */
  margin-top: 20px; /* Space above the table */
  background-color: #f3f3f3; /* Light grey background */
}

.t-test-table th,
.t-test-table td {
  border: 1px solid #ccc;
  padding: 5px; /* Consistent padding for table cells */
  text-align: left; /* Default text alignment */
}

.t-test-table th {
  background-color: #c7c7c7; /* Light background for header */
  vertical-align: bottom; /* Align cell text to the bottom */
}

.t-test-table td input {
  width: 95%; /* Set width to 95% for inputs */
  box-sizing: border-box; /* Include padding and border in width */
}

.calculate-button:hover {
  background-color: #ffffff;
  color:rgb(0, 0, 0)
}
.t-test-table tr:first-child {
  height: 20px; /* Set the desired height for the first row */
} 

.t-test-table td.center,
.t-test-table th.center {
  text-align: center; /* Center text in cells */
}

.t-test-table th:first-child,
.t-test-table td:first-child {
  background-color: #f9f9f9; /* Transparent background */
  border: none; /* No border */
  text-align: center; /* Center text in cells */
  width: 30px; /* Fixed width for the first column */
}


/* Assuming you have a class for your dropdowns */
.dropdown-container {
  margin-top: 20px; /* Adjust the value as needed */
}

.dropdown-label {
  margin-right: 10px;
}


.highlight {
  background-color: green;
  color: white;
}
.results-table{
  text-align: center;
  margin: 10px;
  padding: 10px
}
.results-table td, 
.results-table th {
  text-align: center;
  margin: 5px;
  padding: 5px
}


.results-table th {
  background-color: #c7c7c7; /* Replace with your desired color */
  color: #333; /* Text color, adjust as needed */
  border: #000 1px solid;
}

.mean-std-dev-table th:first-child, .mean-std-dev-table td:first-child {
  background-color: #f9f9f9;
  border: none;
  text-align: center;
  width: 30px;
}


/* CalcTTestwStats.css */



.t-test-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: white;
}

.t-test-table th,
.t-test-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}


.t-test-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.t-test-table input[type="text"],
.t-test-table input[type="number"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.divider {
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}

.tail-type-dropdown,
.highlight-options {
  margin-bottom: 15px;
}

.tail-type-dropdown select,
.highlight-options select,
.highlight-options input[type="number"],
.t-test-container select,
.input-thres-percent, 
.select-thres-percent,
.mean-std-dev-table input[type="text"],
.mean-std-dev-table select, 
.select-line-width,
.select-line-type, 
.line-type-select,
.std-dev-dropdown{
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 5px;
}

.mean-std-dev-table textarea {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 5px;

}


.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
}

.results-table th,
.results-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
}

.results-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.highlight {
  background-color: green;
  color: white;
}


/* Responsive design */
@media (max-width: 768px) {
  .t-test-table,
  .results-table {
    font-size: 14px;
  }

  .t-test-table th,
  .t-test-table td,
  .results-table th,
  .results-table td {
    padding: 8px;
  }

  .explanation-container {
    padding: 15px;
  }
}

.homepage-container {
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  background-color: #797979;
}

.top-box {
  text-align: center;
  margin-bottom: 40px;
  background-color: #333333;
  padding: 40px 30px;
  border-radius: 8px;
  color: #ccc;
  border: 5px solid;
  border-image: linear-gradient(45deg, rgb(0, 0, 52), grey, black) 1;
}

.bento-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 200px);
  gap: 10px;
  margin-bottom: 40px;
}

.box-link {
  background-color: #bdbdbd;
  border-radius: 8px;
  padding: 15px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-link:hover {
  transform: translateY(-2px) translateX(-3px);
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  background-color: #cccccc;
}

.box-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.box-content h3 {
  margin: 0 0 8px 0;
  font-size: 1rem;
}

.box-content p {
  margin: 0;
  font-size: 0.8rem;
  opacity: 0.8;
}

.placeholder-box {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blank-cell {
  background-color: #aaaaaa;
  border-radius: 20px;
}

.photo {
  width: 40%;
  height: 40%;
  align-items: center;
  object-fit: cover;
}

body {
  background-color: #000000; /* Replace with your desired color */
}

.screenshot-warning{
  display: flex; /* Enables Flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 30px;
  color: #ffd5d5;
}